import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class UtilImage {
    // Create Profile Image Path Method
    getProfileImagePath(photoUserValue: string | null): string {
        let returnPath: string;
        if (photoUserValue != null) {
            returnPath = 'sellers/' + photoUserValue;
        } else {
            returnPath ='';
        }
        return returnPath;
    }
}