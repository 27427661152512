import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../assets/environments/environment.prod';
import { UserModel } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private urlApi = environment.urlApiServicesServer;
  constructor(
    private http: HttpClient
  ) { }


  getAllUsersService(tkn:string): Observable<any[]> {
    const httpOptions = {
      headers: this.headers(tkn)
    };
    const urlUsr = this.urlApi + "/usuario/ /1";
    return this.http.get<any[]>(urlUsr, httpOptions);
  }

  findUserByNameEmailService(tkn:string, txtFind: string): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tkn}`
      })
    };
    const urlUsr = this.urlApi + "/usuario/" + txtFind + "/1";
    return this.http.get<any[]>(urlUsr, httpOptions);
  }

  getUserById(tkn: string, userId: string): Observable<UserModel>{
    const httpOptions = {
      headers: this.headers(tkn)
    };
    const urlUsr = this.urlApi + "/usuario/" + userId;
    return this.http.get<UserModel>(urlUsr, httpOptions);
  }

  createUser(tkn: string, user: UserModel): Observable<Boolean> {
    const httpOptions = {
      headers: this.headers(tkn)
    };
    const body = JSON.stringify(user);
    const urlUsr = this.urlApi + "/usuario/"
    return this.http.post<Boolean>(urlUsr, user, httpOptions);
  }

  deleteUserById(tkn: string, userId: string): Observable<Boolean>{
    const httpOptions = {
      headers: this.headers(tkn)
    };
    const urlUsr = this.urlApi + "/usuario/" + userId;
    return this.http.delete<Boolean>(urlUsr, httpOptions);
  }

  updateUserById(tkn: string, user: UserModel) {
    const httpOptions = {
      headers: this.headers(tkn)
    };
    const body = JSON.stringify(user);
    const urlUsr = this.urlApi + "/usuario/" + user.codigo;
    return this.http.put<Boolean>(urlUsr, body, httpOptions);
  }

  headers(tkn:string): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tkn}`
    });
  }

}
