import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from '../../../app.component';
import { RoleEnum } from '../../../shared/enums/role.enum';
import { CompanieEnum } from '../../../shared/enums/companie.enum';
import { UtilImage } from '../../../shared/utils/utilImage';
import { CompanyModel } from '../../../shared/models/company.model';
import { CatalogModel } from '../../../shared/models/catalog.model';
import { CatalogService } from '../../../shared/services/catalog.service';
import { retry } from 'rxjs/operators';
import { GeneralUtils } from '../../../shared/utils/generalUtils';
import { SessionService } from '../../../shared/services/session.service';
import { ListActiveUsersService } from '../../../shared/services/list-active-users.service';


@Component({
  selector: 'app-leftmenu',
  templateUrl: './leftmenu.component.html',
  styleUrls: ['./leftmenu.component.scss'],
  providers: [SessionService, ListActiveUsersService]
})
export class LeftmenuComponent implements OnInit {
  isCollapsed = true;
  Pagecollapse = true;
  PaymentCollapsed = true;
  RoomCollapsed = true;
  PatientCollapsed = true;
  UserCollapsed = true;
  BookingCollapsed = true;
  ccode: string = "";
  ucode: string = "";
  tokn: string = "";
  addBookingRoute: string = "";
  allBookingRoute: string = "";
  addUserRoute: string = "";
  allUserRoute: string = "";
  dashboardRoute: string = "";

  userdata: any;
  ud: any;

  adminRoleCode: number | undefined;

  roleLabelUser: string = '';
  companyCodeUser: number | undefined;
  companyLabelUser: string = '';
  userList: any[] = [];
  companyList!: CompanyModel[];
  selectedCompany!: CompanyModel;
  companyCatalog: CatalogModel[] = [];


  constructor(
    private router: Router,
    private utilImage: UtilImage,
    @Inject(AppComponent)
    private app: AppComponent,
    private catalogService: CatalogService,
    private sessionService: SessionService,
    private activatedRoute: ActivatedRoute,
    public generalUtils: GeneralUtils
  ) {
    if ((this.router.url).includes('users')) {
      this.UserCollapsed = false;
    }
    if ((this.router.url).includes('bookings')) {
      this.BookingCollapsed = false;
    }
  }

  ngOnInit(): void {
    // this.app.themeColor('o');
    this.ccode = this.activatedRoute.snapshot.params['ccode'];
    this.ucode = this.activatedRoute.snapshot.params['ucode'];
    this.tokn = sessionStorage.getItem('tkn') || (localStorage.getItem('tkn_' + this.ccode + '_' + this.ucode) || '');
    this.addUserRoute = '/' + this.ccode + '/' + this.ucode + '/users/add-user';
    this.allUserRoute = '/' + this.ccode + '/' + this.ucode + '/users/all-users';
    this.addBookingRoute = '/' + this.ccode + '/' + this.ucode + '/bookings/add-booking';
    this.allBookingRoute = '/' + this.ccode + '/' + this.ucode + '/bookings/all-bookings';
    this.dashboardRoute = '/' + this.ccode + '/' + this.ucode + '/dashboard';
    this.ud = sessionStorage.getItem('userdata') || (localStorage.getItem('userdata_' + this.ccode + '_' + this.ucode) || '');
    this.userdata = this.ud == '' ? '' : JSON.parse(this.ud);
    this.adminRoleCode = RoleEnum.adminCode;

    switch (this.userdata.rolCodigo) {
      case RoleEnum.adminCode:
        this.roleLabelUser = RoleEnum.adminLabel;
        break;
      case RoleEnum.sellerCode:
        this.roleLabelUser = RoleEnum.sellerLabel;
        break;
    }

    switch (this.userdata.empresaCodigo) {
      case CompanieEnum.gtcCode:
        this.companyLabelUser = CompanieEnum.gtcName;
        this.app.themeColor('o');
        break;
      case CompanieEnum.royalCode:
        this.companyLabelUser = CompanieEnum.royalName;
        this.app.themeColor('bl');
        break;
      default:
        this.app.themeColor('g');
        break;

    }
    this.activeUsersList();
    this.getCompanies(this.tokn);
  }

  ngAfterViewInit() {
    //AGREGAR LOCALSTORAGE
    setTimeout(() => {
      const className = document.getElementById('left-sidebar');
      let colorClassName = document.getElementsByClassName('theme-bg');
      let sdbr = sessionStorage.getItem("Sidebar") || (localStorage.getItem('Sidebar_' + this.ccode + '_' + this.ucode) || '');
      const element = document.querySelector("div#body")!;
      let color = getComputedStyle(element).getPropertyValue('--primary-gradient');
      sessionStorage.setItem('modal-header-color', color);
      localStorage.setItem('modal-header-color_' + this.ccode + '_' + this.ucode, color);
      if (sdbr != "") {
        className?.classList.add(sdbr);
      }
      else {
        className?.classList.remove('light_active');
      }
      for (let index = 0; index < colorClassName.length; index++) {
        const element = colorClassName[index];
        let grc = sessionStorage.getItem("GradientColor") || (localStorage.getItem('GradientColor_' + this.ccode + '_' + this.ucode) || '');
        if (grc != "") {
          element.classList.add('gradient');
        }
        else {
          element.classList.remove('gradient');
        }
      }
    });
  }

  showDropDown() {
    document.getElementById('drp')?.classList.toggle("ShowDiv")
  }

  toggleMenu() {
    const body = document.getElementsByTagName('body')[0];
    let profileIcon = document.getElementById('profile-icon');

    if (body.classList.contains('toggle_menu_active')) {
      body.classList.remove('toggle_menu_active');
      profileIcon!.classList.remove('toggled');
    }
    else {
      body.classList.add('toggle_menu_active');
      profileIcon!.classList.add('toggled');
    }
  }

  cToggoleMenu() {
    const body = document.getElementsByTagName('body')[0].classList.remove("offcanvas-active");
    document.getElementsByClassName('overlay')[0].classList.toggle("open");
  }

  closeSession() {
    sessionStorage.clear();
    this.generalUtils.clearLocalStorage(this.ccode, this.ucode);
    localStorage.setItem('ccode', this.ccode);
    localStorage.setItem('ucode', this.ucode);
    localStorage.setItem('logout-event_' + this.ccode + '_' + this.ucode, Math.random().toString());
    this.sessionService.logOut(this.ccode, this.ucode);
    localStorage.setItem('update-active-users', '1');
  }

  // Get User Profile Path Method
  getProfilePath(photValue: string): string {
    return this.utilImage.getProfileImagePath(photValue);
  }

  firstLetter(name: string): string {
    return name.substring(0, 1).toUpperCase();
  }

  activeUsersList(): void {
    //get active sessions from localstorage
    let search = 'userdata';
    this.userList = Object.keys(localStorage)
      .filter((key) => key.startsWith(search))
      .map((key) => JSON.parse(localStorage[key]));
    //sort list by timestamp 
    this.userList.sort(function (a, b) {
      var keyA = new Date(a.loginTimestamp), keyB = new Date(b.loginTimestamp);
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

  }

  getCompanies(tkn: string) {
    this.catalogService.getCatalog(tkn || '', 'EMPRESA', '0')
      .pipe(retry(2))
      .subscribe(
        (result: any) => {
          this.companyCatalog = result;
        },
        (error: any) => {
          // this.toastr.error("Please contact the system administrator", "There has been an error!");
        });
  }

  getUserCompany(companyCode: number): string {
    let nombre = "";
    this.companyCatalog.forEach((item) => {
      if (item.codigo == companyCode) {
        nombre = item.nombre
      }
    });
    return nombre;
  }

  setCode(value: any): string {
    return btoa(value.toString());
  }

  manageMouseOver(){
    const body = document.getElementsByTagName('body')[0];
    if (body.classList.contains('toggle_menu_active')) {
      let profileIcon = document.getElementById('profile-icon');
      profileIcon!.classList.remove('toggled');
    }
  }

  manageMouseOut(){
    const body = document.getElementsByTagName('body')[0];
    if (body.classList.contains('toggle_menu_active')) {
      let profileIcon = document.getElementById('profile-icon');
      profileIcon!.classList.add('toggled');
    }
  }

}
