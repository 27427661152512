<!-- Main left sidebar menu -->
<div id="left-sidebar" class="sidebar light_active" (mouseover)="manageMouseOver()" (mouseout)="manageMouseOut()">
    <a href="javascript:void(0);" (click)="toggleMenu()" class="menu_toggle"><i class="fa fa-angle-left"></i></a>
    <div class="navbar-brand">
        <a [routerLink]="[dashboardRoute]" href="javascript:void(0)"><img src="assets/images/online-booking.png"
                alt="BTS Logo" class="img-fluid logo"><span>B.T.S.</span> <span style="font-size: 12px;">v2.0</span></a>
        <button type="button" (click)="cToggoleMenu()" class="btn-toggle-offcanvas btn btn-sm float-right"><i
                class="fa fa-close"></i></button>
    </div>
    <div class="sidebar-scroll">
        <div class="user-account">
            <div class="user_div">
                <img src="assets/images/{{getProfilePath(userdata.foto)}}" class="user-photo" alt="User Profile Picture"
                    *ngIf="getProfilePath(userdata.foto).length != 0">
                <div id="profile-icon" class="user-photo empty-img theme-bg gradient"
                    *ngIf="getProfilePath(userdata.foto).length == 0">
                    <span>
                        {{firstLetter(userdata.nombres)}}
                    </span>
                </div>
            </div>
            <div class="dropdown">
                <span>{{roleLabelUser}},</span>
                <span>
                    <a href="javascript:void(0);" class="dropdown-toggle user-name" (click)="showDropDown()"
                        data-toggle="dropdown"><strong>{{userdata.nombres}}<br/>{{userdata.apellidos}}</strong></a>
                </span>
                <ul style="right: 0; left: -4rem !important; min-width: 15.55rem;"
                    class="dropdown-menu dropdown-menu-right account vivify flipInY custom-dropdown" id="drp">
                    <li style="overflow-wrap: normal;text-align: center;font-weight: bolder;font-size: 0.8rem;">
                        {{userdata.email}}</li>
                    <hr />
                    <li *ngIf="userList.length!=0" class="header">Active accounts</li>
                    <ul *ngIf="userList.length!=0" style="list-style-type:none; padding: 10px 0;">
                        <li *ngFor="let item of userList" style="display: inline-flex; padding-bottom: 3px;">
                            <div class="user_div" style="padding: 2px;">
                                <img src="assets/images/{{getProfilePath(item.foto)}}" class="user-photo-company"
                                    alt="User Profile Picture" *ngIf="getProfilePath(item.foto).length != 0">
                                <div class="{{generalUtils.setCompanycolor(item.empresaCodigo)}}">
                                    <div class="user-photo-company account-icon theme-bg gradient"
                                        *ngIf="getProfilePath(item.foto).length == 0">
                                        <span>
                                            {{firstLetter(item.nombres)}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <a style="padding: 0 0 0 5px;"
                                [routerLink]="[''+'/'+setCode(item.empresaCodigo)+'/'+setCode(item.codigo)+'/dashboard']"
                                target="_blank">{{item.nombres}} {{item.apellidos}} -
                                {{getUserCompany(item.empresaCodigo)}}
                            </a>
                        </li>
                    </ul>
                    <hr *ngIf="userList.length!=0">
                    <li><a [routerLink]="['/login']" href="javascript:void(0)" target="_blank">Add other
                            account</a>
                    </li>
                    <hr />
                    <li><a [routerLink]="['/login']" href="javascript:void(0)" (click)="closeSession()"><i
                                class="fa fa-power-off"></i>Logout</a>
                    </li>
                </ul>
            </div>
        </div>
        <nav id="left-sidebar-nav" class="sidebar-nav">
            <ul id="main-menu" class="metismenu animation-li-delay">

                <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"><a
                        [routerLink]="[dashboardRoute]" href="javascript:void(0)"><i class="fa fa-dashboard"></i>
                        <span>Dashboard</span></a></li>

                <!-- SECTION ADMIN -->
                <li class="header" *ngIf="userdata.rolCodigo==adminRoleCode">Admin</li>

                <li *ngIf="userdata.rolCodigo==adminRoleCode" [routerLinkActive]="['active']"
                    [routerLinkActiveOptions]="{exact:true}">
                    <a (click)="UserCollapsed = !UserCollapsed" [attr.aria-expanded]="!UserCollapsed"
                        aria-controls="collapseBasic" href="javascript:void(0)" class="has-arrow"><i
                            class="fa fa-user-circle-o"></i><span>Users</span></a>
                    <ul id="collapseBasic" [collapse]="UserCollapsed" [isAnimated]="true">
                        <li [routerLinkActive]="['active']"><a [routerLink]="[allUserRoute]"
                                href="javascript:void(0)">All Users</a></li>
                        <li [routerLinkActive]="['active']"><a [routerLink]="[addUserRoute]"
                                href="javascript:void(0)">Add User</a></li>
                        <!-- <li><a [routerLink]="['/users/user-profile']" href="javascript:void(0)">User Profile</a></li> -->
                    </ul>
                </li>
                <!-- / SECTION ADMIN -->

                <!-- SECTION BOOKINGS -->
                <li class="header">Management</li>

                <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                    <a (click)="BookingCollapsed = !BookingCollapsed" [attr.aria-expanded]="!BookingCollapsed"
                        aria-controls="collapseBasic" href="javascript:void(0)" class="has-arrow"><i
                            class="fa fa-th-list"></i><span>Bookings</span></a>
                    <ul id="collapseBasic" [collapse]="BookingCollapsed" [isAnimated]="true">
                        <li [routerLinkActive]="['active']"><a [routerLink]="[allBookingRoute]"
                                href="javascript:void(0)">All Bookings</a></li>
                        <li [routerLinkActive]="['active']"><a [routerLink]="[addBookingRoute]"
                                href="javascript:void(0)" *ngIf="userdata.rolCodigo==adminRoleCode">Add Booking</a></li>
                    </ul>
                </li>
                <!-- / SECTION BOOKINGS -->
            </ul>
        </nav>
    </div>
</div>