<!-- Page top navbar -->
<nav class="navbar navbar-fixed-top">
    <div class="container-fluid">
        <div class="navbar-left">
            <div class="navbar-btn">
                <a href="index.html"><img src="assets/images/online-booking.png" alt="Mooli Logo" class="img-fluid logo"></a>
                <button type="button" (click)="mToggoleMenu()" class="btn-toggle-offcanvas"><i
                        class="fa fa-align-left"></i></button>
            </div>
        </div>
        <div class="navbar-right">
            <div id="navbar-menu">
                <ul class="nav navbar-nav">                    
                    <li class="hidden-xs">
                        <a *ngIf="!isFullScreen" href="javascript:void(0);" (click)="openfullScreen()"
                            id="btnFullscreen" class="icon-menu"><i class="fa fa-arrows-alt"></i></a>
                        <a *ngIf="isFullScreen" href="javascript:void(0);" (click)="closeFullScreen()"
                            id="btnFullscreen" class="icon-menu"><i class="fa fa-arrows-alt"></i></a>
                    </li>
                    <li><a [routerLink]="['/login']" href="javascript:void(0)" class="icon-menu" (click)="closeSession()"><i
                                class="fa fa-power-off"></i></a></li>
                </ul>
            </div>
        </div>
        <div class="recent_searche" style="display: none;">
            <div class="card mb-0">
                <div class="header">
                    <h2>Recent search result</h2>
                    <ul class="header-dropdown dropdown">
                        <li><a href="javascript:void(0);">Clear data</a></li>
                        <li><a href="page-search-results.html"><i class="fa fa-external-link"></i></a></li>
                    </ul>
                </div>
                <div class="body">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item p-l-0 p-r-0">
                            <h6><a href="#">Crush it - Bootstrap Admin Application Template &amp; Ui Kit</a></h6>
                            <p class="text-muted">It is a long established fact that a reader will be distracted.</p>
                            <div class="text-muted font-13">
                                <ul class="list-inline">
                                    <li class="list-inline-item"><span
                                            class="badge badge-secondary margin-0">React</span></li>
                                    <li class="list-inline-item">Dec 27 2020</li>
                                </ul>
                            </div>
                        </li>
                        <li class="list-group-item p-l-0 p-r-0">
                            <h6><a href="#">Epic Pro - HR &amp; Project Management Admin Template and UI Kit</a></h6>
                            <p class="text-muted">he point of using Lorem Ipsum is that it has a more-or-less English.
                            </p>
                            <div class="text-muted font-13">
                                <ul class="list-inline">
                                    <li class="list-inline-item"><span class="badge badge-success margin-0">HTML</span>
                                    </li>
                                    <li class="list-inline-item">Oct 13 2020</li>
                                </ul>
                            </div>
                        </li>
                        <li class="list-group-item p-l-0 p-r-0">
                            <h6><a href="#">Qubes - Responsive Admin Dashboard Template</a></h6>
                            <p class="text-muted">Commodo excepteur non ut aliqua ex qui velit sed esse consequat in
                            </p>
                            <div class="text-muted font-13">
                                <ul class="list-inline">
                                    <li class="list-inline-item"><span
                                            class="badge badge-danger margin-0">Bootstrap</span></li>
                                    <li class="list-inline-item">Sep 27 2020</li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</nav>

<app-leftmenu></app-leftmenu>

<!-- Right bar chat  -->
<div id="rightbar" class="rightbar">
    <div class="slim_scroll">
        <div class="chat_list">
            <form>
                <div class="input-group c_input_group">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-magnifier"></i></span>
                    </div>
                    <input type="text" class="form-control" placeholder="Search...">
                </div>
            </form>
            <div class="body">
                <ul class="nav nav-tabs3 white mt-3 d-flex text-center">
                    <li class="nav-item flex-fill"><a data-toggle="tab" (click)="onTab('1')"
                            [ngClass]="{'active': chatTab}" class="nav-link" href="javascript:void(0)">Chat</a>
                    </li>
                    <li class="nav-item flex-fill"><a data-toggle="tab" [ngClass]="{'active': groupTab}"
                            class="nav-link" (click)="onTab('2')" href="javascript:void(0)">Groups</a></li>
                    <li class="nav-item flex-fill"><a data-toggle="tab" [ngClass]="{'active': contactTab}"
                            class="nav-link mr-0" (click)="onTab('3')" href="javascript:void(0)">Contact</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane vivify fadeIn active show" id="chat-Users" [ngClass]="{'active': chatTab}">
                        <ul class="right_chat list-unstyled mb-0 animation-li-delay">
                            <li class="online">
                                <a href="javascript:void(0);" class="media">
                                    <img class="media-object" src="assets/images/xs/avatar4.jpg" alt="">
                                    <div class="media-body">
                                        <span class="name">Louis Henry <small class="text-muted font-12">10
                                                min</small></span>
                                        <span class="message">How do you do?</span>
                                        <span class="badge badge-outline status"></span>
                                    </div>
                                </a>
                            </li>
                            <li class="online">
                                <a href="javascript:void(0);" class="media">
                                    <img class="media-object " src="assets/images/xs/avatar5.jpg" alt="">
                                    <div class="media-body">
                                        <span class="name">Debra Stewart <small class="text-muted font-12">15
                                                min</small></span>
                                        <span class="message">I was wondering...</span>
                                        <span class="badge badge-outline status"></span>
                                    </div>
                                </a>
                            </li>
                            <li class="offline">
                                <a href="javascript:void(0);" class="media">
                                    <img class="media-object " src="assets/images/xs/avatar2.jpg" alt="">
                                    <div class="media-body">
                                        <span class="name">Lisa Garett <small class="text-muted font-12">18
                                                min</small></span>
                                        <span class="message">I've forgotten how it felt before</span>
                                        <span class="badge badge-outline status"></span>
                                    </div>
                                </a>
                            </li>
                            <li class="offline">
                                <a href="javascript:void(0);" class="media">
                                    <img class="media-object " src="assets/images/xs/avatar1.jpg" alt="">
                                    <div class="media-body">
                                        <span class="name">Folisise Chosielie <small class="text-muted font-12">23
                                                min</small></span>
                                        <span class="message">Wasup for the third time like...</span>
                                        <span class="badge badge-outline status"></span>
                                    </div>
                                </a>
                            </li>
                            <li class="online">
                                <a href="javascript:void(0);" class="media">
                                    <img class="media-object " src="assets/images/xs/avatar3.jpg" alt="">
                                    <div class="media-body">
                                        <span class="name">Marshall Nichols <small class="text-muted font-12">27
                                                min</small></span>
                                        <span class="message">But we’re probably gonna need a new carpet.</span>
                                        <span class="badge badge-outline status"></span>
                                    </div>
                                </a>
                            </li>
                            <li class="online">
                                <a href="javascript:void(0);" class="media">
                                    <img class="media-object " src="assets/images/xs/avatar5.jpg" alt="">
                                    <div class="media-body">
                                        <span class="name">Debra Stewart <small class="text-muted font-12">38
                                                min</small></span>
                                        <span class="message">It’s not that bad...</span>
                                        <span class="badge badge-outline status"></span>
                                    </div>
                                </a>
                            </li>
                            <li class="offline">
                                <a href="javascript:void(0);" class="media">
                                    <img class="media-object " src="assets/images/xs/avatar2.jpg" alt="">
                                    <div class="media-body">
                                        <span class="name">Lisa Garett <small class="text-muted font-12">45
                                                min</small></span>
                                        <span class="message">How do you do?</span>
                                        <span class="badge badge-outline status"></span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-pane vivify fadeIn" id="chat-Groups" [ngClass]="{'active': groupTab}">
                        <ul class="right_chat list-unstyled mb-0 animation-li-delay">
                            <li class="online">
                                <a href="javascript:void(0);" class="media">
                                    <img class="media-object" src="assets/images/xs/avatar4.jpg" alt="">
                                    <div class="media-body">
                                        <span class="name">PHP Groups<small class="text-muted font-12">10
                                                min</small></span>
                                        <span class="message">How do you do?</span>
                                        <span class="badge badge-outline status"></span>
                                    </div>
                                </a>
                            </li>
                            <li class="offline">
                                <a href="javascript:void(0);" class="media">
                                    <img class="media-object " src="assets/images/xs/avatar2.jpg" alt="">
                                    <div class="media-body">
                                        <span class="name">Family Groups <small class="text-muted font-12">18
                                                min</small></span>
                                        <span class="message">I've forgotten how it felt before</span>
                                        <span class="badge badge-outline status"></span>
                                    </div>
                                </a>
                            </li>
                            <li class="offline">
                                <a href="javascript:void(0);" class="media">
                                    <img class="media-object " src="assets/images/xs/avatar1.jpg" alt="">
                                    <div class="media-body">
                                        <span class="name">Friends holic <small class="text-muted font-12">23
                                                min</small></span>
                                        <span class="message">Wasup for the third time like...</span>
                                        <span class="badge badge-outline status"></span>
                                    </div>
                                </a>
                            </li>
                            <li class="offline">
                                <a href="javascript:void(0);" class="media">
                                    <img class="media-object " src="assets/images/xs/avatar2.jpg" alt="">
                                    <div class="media-body">
                                        <span class="name">CL City 2 <small class="text-muted font-12">45
                                                min</small></span>
                                        <span class="message">How do you do?</span>
                                        <span class="badge badge-outline status"></span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-pane vivify fadeIn" id="chat-Contact" [ngClass]="{'active': contactTab}">
                        <ul class="right_chat list-unstyled mb-0 animation-li-delay">
                            <li class="offline">
                                <a href="javascript:void(0);" class="media">
                                    <img class="media-object " src="assets/images/xs/avatar2.jpg" alt="">
                                    <div class="media-body">
                                        <span class="name">John Smith <small class="text-muted"><i
                                                    class="fa fa-star"></i></small></span>
                                        <span class="message">johnsmith&#64;info.com</span>
                                        <span class="badge badge-outline status"></span>
                                    </div>
                                </a>
                            </li>
                            <li class="offline">
                                <a href="javascript:void(0);" class="media">
                                    <img class="media-object " src="assets/images/xs/avatar1.jpg" alt="">
                                    <div class="media-body">
                                        <span class="name">Merri Diamond <small class="text-muted"><i
                                                    class="fa fa-heart"></i></small></span>
                                        <span class="message">hermanbeck&#64;info.com</span>
                                        <span class="badge badge-outline status"></span>
                                    </div>
                                </a>
                            </li>
                            <li class="online">
                                <a href="javascript:void(0);" class="media">
                                    <img class="media-object" src="assets/images/xs/avatar4.jpg" alt="">
                                    <div class="media-body">
                                        <span class="name">Louis Henry <small class="text-muted"><i
                                                    class="fa fa-star"></i></small></span>
                                        <span class="message">maryadams&#64;info.com</span>
                                        <span class="badge badge-outline status"></span>
                                    </div>
                                </a>
                            </li>
                            <li class="online">
                                <a href="javascript:void(0);" class="media">
                                    <img class="media-object " src="assets/images/xs/avatar5.jpg" alt="">
                                    <div class="media-body">
                                        <span class="name">Andrew Patrick <small class="text-muted"><i
                                                    class="fa fa-star"></i></small></span>
                                        <span class="message">mikethimas&#64;info.com</span>
                                        <span class="badge badge-outline status"></span>
                                    </div>
                                </a>
                            </li>
                            <li class="online">
                                <a href="javascript:void(0);" class="media">
                                    <img class="media-object " src="assets/images/xs/avatar3.jpg" alt="">
                                    <div class="media-body">
                                        <span class="name">Claire Peters <small class="text-muted"><i
                                                    class="fa fa-heart"></i></small></span>
                                        <span class="message">clairepeters&#64;info.com</span>
                                        <span class="badge badge-outline status"></span>
                                    </div>
                                </a>
                            </li>
                            <li class="online">
                                <a href="javascript:void(0);" class="media">
                                    <img class="media-object " src="assets/images/xs/avatar5.jpg" alt="">
                                    <div class="media-body">
                                        <span class="name">Debra Stewart <small class="text-muted"><i
                                                    class="fa fa-star"></i></small></span>
                                        <span class="message">It’s not that bad...</span>
                                        <span class="badge badge-outline status"></span>
                                    </div>
                                </a>
                            </li>
                            <li class="offline">
                                <a href="javascript:void(0);" class="media">
                                    <img class="media-object " src="assets/images/xs/avatar2.jpg" alt="">
                                    <div class="media-body">
                                        <span class="name">Lisa Garett <small class="text-muted"><i
                                                    class="fa fa-star"></i></small></span>
                                        <span class="message">eringonzales&#64;info.com</span>
                                        <span class="badge badge-outline status"></span>
                                    </div>
                                </a>
                            </li>
                            <li class="online">
                                <a href="javascript:void(0);" class="media">
                                    <img class="media-object" src="assets/images/xs/avatar4.jpg" alt="">
                                    <div class="media-body">
                                        <span class="name">Louis Henry <small class="text-muted"><i
                                                    class="fa fa-star"></i></small></span>
                                        <span class="message">susiewillis&#64;info.com</span>
                                        <span class="badge badge-outline status"></span>
                                    </div>
                                </a>
                            </li>
                            <li class="online">
                                <a href="javascript:void(0);" class="media">
                                    <img class="media-object " src="assets/images/xs/avatar5.jpg" alt="">
                                    <div class="media-body">
                                        <span class="name">Debra Stewart <small class="text-muted"><i
                                                    class="fa fa-star"></i></small></span>
                                        <span class="message">johnsmith&#64;info.com</span>
                                        <span class="badge badge-outline status"></span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Stiky note div  -->
<div class="sticky-note">
    <a href="javascript:void(0);" class="right_note" (click)="noteToggle()"><i class="fa fa-close"></i></a>
    <div class="form-group c_form_group">
        <label>Write your note here</label>
        <div class="input-group">
            <input type="text" class="form-control" placeholder="Enter here...">
            <div class="input-group-append"><button class="btn btn-dark btn-sm" type="button">Add</button></div>
        </div>
    </div>
    <div class="note-body">
        <div class="card note-item">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <span class="font-10 text-muted">12 Apr 2020</span>
                </div>
                <div>
                    <a href="javascript:void(0);" class="star"><i class="fa fa-star-o"></i></a>
                    <a href="javascript:void(0);" class="delete"><i class="fa fa-trash"></i></a>
                </div>
            </div>
            <div class="note-detail">
                <span>Commit code on github branch development</span>
            </div>
        </div>
        <div class="card note-item">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <span class="font-10 text-muted">12 Apr 2020</span>
                </div>
                <div>
                    <a href="javascript:void(0);" class="star active"><i class="fa fa-star-o"></i></a>
                    <a href="javascript:void(0);" class="delete"><i class="fa fa-trash"></i></a>
                </div>
            </div>
            <div class="note-detail">
                <span>Meeting with client for new project.</span>
            </div>
        </div>
        <div class="card note-item">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <span class="font-10 text-muted">12 Apr 2020</span>
                </div>
                <div>
                    <a href="javascript:void(0);" class="star"><i class="fa fa-star-o"></i></a>
                    <a href="javascript:void(0);" class="delete"><i class="fa fa-trash"></i></a>
                </div>
            </div>
            <div class="note-detail">
                <span>making this the first true generator on the Internet</span>
            </div>
        </div>
        <div class="card note-item">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <span class="font-10 text-muted">12 Apr 2020</span>
                </div>
                <div>
                    <a href="javascript:void(0);" class="star"><i class="fa fa-star-o"></i></a>
                    <a href="javascript:void(0);" class="delete"><i class="fa fa-trash"></i></a>
                </div>
            </div>
            <div class="note-detail">
                <span>it look like readable English. Many desktop publishing</span>
            </div>
        </div>
    </div>
</div>