import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { LeftmenuComponent } from './components/leftmenu/leftmenu.component';
import { FormsModule } from '@angular/forms';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { RadarChartComponent } from './components/charts/radar-chart/radar-chart.component';
import { Error404Component } from './components/error404/error404.component';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LeftmenuComponent,
    Error404Component
  ],
  imports: [
    CommonModule,
    RouterModule,
    CollapseModule,
    FormsModule
  ], 
  exports:[
    HeaderComponent,
    FooterComponent
  ]
})
export class CoreModule { }
