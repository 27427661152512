import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeneralUtils } from '../../../shared/utils/generalUtils';
import { SessionService } from '../../../shared/services/session.service';
import { ListActiveUsersService } from '../../../shared/services/list-active-users.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [SessionService, ListActiveUsersService]
})
export class HeaderComponent implements OnInit {
  isFullScreen: boolean = false;
  contactTab: boolean = false;
  groupTab: boolean = false;
  chatTab: boolean = true;
  ccode: string = "";
  ucode: string = "";

  constructor(
    private activatedRoute: ActivatedRoute,
    private generalUtils: GeneralUtils,
    private sessionService: SessionService,
    private listActiveUsersService: ListActiveUsersService
  ) { }

  ngOnInit(): void {
    this.ccode = this.activatedRoute.snapshot.params['ccode'];
    this.ucode = this.activatedRoute.snapshot.params['ucode'];

    // setTimeout(() => {

    //   document.getElementsByClassName('page-loader-wrapper')[0].classList.add("HideDiv");

    // }, 1000);
  }
  mToggoleMenu() {
    document.getElementsByTagName('body')[0].classList.toggle("offcanvas-active");
    document.getElementsByClassName('overlay')[0].classList.toggle("open");

  }
  noteToggle() {
    document.getElementsByClassName('sticky-note')[0].classList.toggle("open");
    document.getElementsByClassName('overlay')[0].classList.toggle("open");
  }
  openRightMenu() {
    document.getElementById('rightbar')?.classList.toggle("open");
    document.getElementsByClassName('overlay')[0].classList.toggle("open");

  }
  openfullScreen() {

    let elem: any = document.documentElement;
    let methodToBeInvoked = elem.requestFullscreen ||
      elem.requestFullscreen || elem['mozRequestFullscreen'] || elem['msRequestFullscreen'];
    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem)
    }
    this.isFullScreen = true;
  }

  closeFullScreen() {
    const docWithBrowsersExitFunctions = document as Document & {
      mozCancelFullScreen(): Promise<void>;
      webkitExitFullscreen(): Promise<void>;
      msExitFullscreen(): Promise<void>;
    };
    if (docWithBrowsersExitFunctions.exitFullscreen) {
      docWithBrowsersExitFunctions.exitFullscreen();
    } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) { /* Firefox */
      docWithBrowsersExitFunctions.mozCancelFullScreen();
    } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      docWithBrowsersExitFunctions.webkitExitFullscreen();
    } else if (docWithBrowsersExitFunctions.msExitFullscreen) { /* IE/Edge */
      docWithBrowsersExitFunctions.msExitFullscreen();
    }
    this.isFullScreen = false;
  }

  onTab(number: any) {
    this.chatTab = false;
    this.groupTab = false;
    this.contactTab = false;
    if (number == '1') {
      this.chatTab = true;
    }
    else if (number == '2') {
      this.groupTab = true;
    }
    else if (number == '3') {
      this.contactTab = true;
    }
  }

  closeSession() {
    sessionStorage.clear();
    this.generalUtils.clearLocalStorage(this.ccode, this.ucode);
    localStorage.setItem('ccode', this.ccode);
    localStorage.setItem('ucode', this.ucode);
    localStorage.setItem('logout-event_' + this.ccode + '_' + this.ucode, Math.random().toString());
    this.sessionService.logOut(this.ccode, this.ucode);
    localStorage.setItem('update-active-users', '1');
  }

}
