<div class="auth-main">
    <div class="auth_div vivify fadeIn">
        <div class="auth_brand">
            <h1><i class="fa fa-exclamation-circle" aria-hidden="true"></i> 404</h1>
            <h5>Page not found</h5>
        </div>
    </div>
    <div class="animate_lines" style="--primary-color:darkslategray;--secondary-color:lightgreen">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>